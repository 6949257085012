const underConstruction = document.querySelector('.under_construction');
const previewButton = document.getElementById('preview');
console.log("Preview Button :::", previewButton)
previewButton.addEventListener('click', () => {
    console.log("Under Construction :::", underConstruction)
    underConstruction.classList.toggle('hidden'); 
        if (underConstruction.classList.contains('hidden')) {
            previewButton.innerHTML = 'Exit Preview';
        } else {
            previewButton.innerHTML = 'Preview Photo Galleries';
        }
});
